import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const AdminLogin = React.lazy(() => import('../pages/auth/AdminLogin'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ConfirmPhone = React.lazy(() => import('../pages/auth/ConfirmPhone'));
const EmailVerified = React.lazy(() => import('../pages/auth/EmailVerified'));
const ThankYou = React.lazy(() => import('../pages/auth/ThankYou'));
const ConfirmEmail = React.lazy(() => import('../pages/auth/ConfirmEmail'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard-v2'));
const dashboardCitizen = React.lazy(() => import('../pages/admin-dashboard/Citizens'));
const adminDashboardv2 = React.lazy(() => import('../pages/dashboard-v2'));

// user
const ProfilePage = React.lazy(() => import('../pages/profilepage'));
const submitIssue = React.lazy(() => import('../pages/submit-issue'));
const pollingServices = React.lazy(() => import('../pages/polling-services'));
const userIssues = React.lazy(() => import('../pages/user-issues'));
const ideas = React.lazy(() => import('../pages/ideas'));

//officer
const MDANodalOfficer = React.lazy(() => import('../pages/dashboard'));
const MDANodalOfficerIssues = React.lazy(() => import('../pages/mda-nodal-officer-issues'));
const OfficerIssues = React.lazy(() => import('../pages/officerissues/OfficerIssues'));
const RerouteIssues = React.lazy(() => import('../pages/officerissues/RerouteIssues'));
const ChangePassword = React.lazy(() => import('../pages/changepassword'));

//control center
const IssueReRoutes = React.lazy(() => import('../pages/issue-re-routes'));
const MDAIssues = React.lazy(() => import('../pages/mda-issues'));

// Admin
const MDA = React.lazy(() => import('../pages/mda/MDA'));
const MDAUser = React.lazy(() => import('../pages/mdauser'));
const DataVariable = React.lazy(() => import('../pages/datavariable/DataVariable'));
const Issues = React.lazy(() => import('../pages/issues/Issues'));
const Polling = React.lazy(() => import('../pages/polling/Polling'));
const Infographics = React.lazy(() => import('../pages/infographics/Infographics'));
const AllUsers = React.lazy(() => import('../pages/users'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// call center
const CallsHistory = React.lazy(() => import('../pages/callshistory'));
const CallHistory = React.lazy(() => import('../pages/callhistory'));
const ReceiveCall = React.lazy(() => import('../pages/receivecall'));

// chat
const ContactMinistry = React.lazy(() => import('../pages/contact-ministry'));
const ContactCallCenter = React.lazy(() => import('../pages/contact-call-center'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () =>{ 
				const loggedInUser = getLoggedInUser();
				if(loggedInUser.role=='Administrator'){
					return <Redirect to="/admin/dashboard" />
				}else if(loggedInUser.role=='Super Ministry'){
					return <Redirect to="/ministry/dashboard" />
				/*}else if(loggedInUser.role=='Control Center'){
					return <Redirect to="/re-routes" />*/
				/*}else if(loggedInUser.role=='Call Center'){
					return <Redirect to="/admin/call-center-dashboard" />
				*/}else{
					return <Redirect to="/dashboard" />
				}
			},
    route: PrivateRoute,
};

const loggedInUser = getLoggedInUser();

// dashboards
const dashboardRoutes = {
    path: '/admin/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: Dashboard,
    roles: ['Administrator'],
    route: PrivateRoute
};

// dashboards
const dashboardSuperMinistryRoutes = {
    path: '/ministry/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: Dashboard,
    roles: ['Super Ministry'],
    route: PrivateRoute
};

const dashboardCitizenRoutes = {
    path: '/admin/dashboard/citizens',
    name: 'Citizens',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: dashboardCitizen,
    roles: ['Administrator'],
    route: PrivateRoute
};

const profilepageRoutes = {
    path: '/profile',
    name: 'Profile',
    icon: FeatherIcon.User,
    component: ProfilePage,
    route: PrivateRoute,
    roles: ['User'],
};

const submitIssueRoutes = {
    path: '/submit-issue',
    name: 'Submit an Issue',
    icon: FeatherIcon.AlertCircle,
    component: submitIssue,
    route: PrivateRoute,
    roles: ['User'],
};

const mdaRoutes = {
    path: '/admin/mdas',
    name: 'MDA',
    icon: FeatherIcon.AlignRight,
    component: MDA,
    route: PrivateRoute,
    roles: ['Administrator'],
};

const mdauserRoutes = {
    path: '/admin/mda-users',
    name: 'MDA Users',
    icon: FeatherIcon.Users,
    component: MDAUser,
    route: PrivateRoute,
    roles: ['Administrator'],
};

const datavariableRoutes = {
    path: '/admin/data-variables',
    name: 'Data Variables',
    icon: FeatherIcon.FileText,
    component: DataVariable,
    route: PrivateRoute,
    roles: ['Administrator'],
};
const issuesRoutes = {
    path: '/admin/issues',
    name: 'Issues',
    icon: FeatherIcon.AlertCircle,
    component: Issues,
    route: PrivateRoute,
    roles: ['Admin1'],
};

const pollingRoutes = {
    path: '/admin/polling',
    name: 'Polling',
    icon: FeatherIcon.UserPlus,
    component: Polling,
    route: PrivateRoute,
    roles: ['Administrator'],
};

const infographicsRoutes = {
    path: '/admin/infographics',
    name: 'Infographics',
    icon: FeatherIcon.Codesandbox,
    component: Infographics,
    route: PrivateRoute,
    roles: ['Administrator'],
};

const AllUsersRoutes = {
    path: '/admin/all-users',
    name: 'All Users',
    icon: FeatherIcon.Users,
    component: AllUsers,
    route: PrivateRoute,
    roles: ['Administrator'],
};

const pollingServicesRoutes = {
    path: '/polling-services',
    name: 'Polling Services ',
    icon: FeatherIcon.UserPlus,
    component: pollingServices,
    route: PrivateRoute,
	exact: true,
    roles: ['User'],
};

const userIssuesRoutes = {
    path: '/issues',
    name: 'Issues ',
    icon: FeatherIcon.AlertCircle,
    component: userIssues,
    route: PrivateRoute,
	exact: true,
    roles: ['User', 'Control Center'],
};

const changepasswordRoutes = {
    path: '/change-password',
    name: 'Change Password',
    icon: FeatherIcon.EyeOff,
    component: ChangePassword,
    route: PrivateRoute,
    roles: ['Nodal Officer','Nodal Supervisor'],
};

const mdanodalofficerRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: MDANodalOfficer,
    route: PrivateRoute,
    roles: ['User','Nodal Officer','Nodal Supervisor','Control Center','Ministry Interface','Call Center'],
};

const ideasRoutes = {
    path: '/ideas',
    name: <span>Ideas <span className="menu-item-counts d-none" id="ideas_total_counts">0</span></span>,
    icon: FeatherIcon.Award,
    component: ideas,
    route: PrivateRoute,
    roles: ['User','Nodal Officer','Nodal Supervisor','Control Center', 'Administrator'],
};

const mdaNodalOfficerIssuesRoutes = {
    path: '/issues',
    name: 'Issues',
    icon: FeatherIcon.AlertCircle,
    component: MDANodalOfficerIssues,
    route: PrivateRoute,
    roles: ['Nodal Officer','Nodal Supervisor','Ministry Interface'],
};

const officerIssuesRoutes = {
    path: '/issue',
    name: 'Issues',
    icon: FeatherIcon.AlertCircle,
    component: OfficerIssues,
    route: PrivateRoute,
	exact: true,
    roles: ['Nodal Officer','Nodal Supervisor', 'Ministry Interface'],
};

const reRouteIssuesRoutes = {
    path: '/issue-re-routes',
    name: 'Issues',
    icon: FeatherIcon.AlertCircle,
    component: RerouteIssues,
    route: PrivateRoute,
	exact: true,
    roles: ['Nodal Officer','Nodal Supervisor', 'Ministry Interface'],
};

const IssuesReRoutesRoutes = {
    path: '/re-routes',
    name: <span>Re-routes <span className="re-routes-counts" id="control_center-re_routes_counts">0</span></span>,
    icon: FeatherIcon.AlertCircle,
    component: IssueReRoutes,
    route: PrivateRoute,
	exact: true,
    roles: ['Control Center'],
};

//call center
const callsHistoryRoutes = {
    path: '/admin/calls-history',
    name: 'Calls History',
    icon: FeatherIcon.Phone,
    component: CallsHistory,
    route: PrivateRoute,
    roles: ['Administrator'],
};
const adminDashboardv2Routes = {
    path: '/admin/admin-dashboard',
    name: 'Dashboard V2',
    icon: FeatherIcon.Home,
    component: adminDashboardv2,
    route: PrivateRoute,
    roles: ['Administrator'],
};
const receiveCallRoutes = {
    path: '/admin/receive-call',
    name: 'Receive Call',
    icon: FeatherIcon.Phone,
    component: ReceiveCall,
    route: PrivateRoute,
    roles: ['Call Center'],
};
const callHistoryRoutes = {
    path: '/calls-history',
    name: 'Calls History',
    icon: FeatherIcon.Phone,
    component: CallHistory,
    route: PrivateRoute,
    roles: ['Call Center'],
};

const chatRoutes = {
    path: '/contact-ministry',
    name: <span>Contact Ministry <span className="menu-item-counts" id="contact_ministry-chat_counts">0</span></span>,
    icon: FeatherIcon.MessageSquare,
    component: ContactMinistry,
    route: PrivateRoute,
    roles: ['Control Center'],
};

const chatMinistryRoutes = {
    path: '/contact-control-center',
    name: <span>Contact Control Center <span className="menu-item-counts" id="contact_ministry-chat_counts">0</span></span>,
    icon: FeatherIcon.MessageSquare,
    component: ContactMinistry,
    route: PrivateRoute,
    roles: ['Nodal Officer','Nodal Supervisor'],
};

const chatCallCenterRoutes = {
    path: '/contact-call-center',
    name: <span>Contact Call Center <span className="menu-item-counts" id="contact_call_center-chat_counts">0</span></span>,
    icon: FeatherIcon.MessageSquare,
    component: ContactCallCenter,
    route: PrivateRoute,
    roles: ['Control Center'],
};

const chatControlCenterRoutes = {
    path: '/contact-controlcenter',
    name: <span>Contact Control Center <span className="menu-item-counts" id="contact_call_center-chat_counts">0</span></span>,
    icon: FeatherIcon.MessageSquare,
    component: ContactCallCenter,
    route: PrivateRoute,
    roles: ['Call Center'],
};

const issueListRoutes = {
    path: '/mda-issues/:mda_id/:status',
    name: 'Issues',
    icon: FeatherIcon.AlertCircle,
    component: MDAIssues,
    route: PrivateRoute,
    roles: ['Control Center'],
};



// apps
const appRoutes = [];

// pages

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm-phone',
            name: 'Confirm Phone Number',
            component: ConfirmPhone,
            route: Route,
        },
        {
            path: '/email-confirmed',
            name: 'Email Confirmed',
            component: ConfirmEmail,
            route: Route,
        },       
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/email-verified/:passed?',
            name: 'Confirm',
            component: EmailVerified,
            route: Route,
        },
        {
            path: '/account/thank-you',
            name: 'Thank You',
            component: ThankYou,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
		{
            path: '/admin/login',
            name: 'Administrator Login',
            component: AdminLogin,
            route: Route,
        },		
		{
            path: '/admin',
            name: 'Administrator Login',
            component: () => <Redirect to="/admin/login" />,
			exact: true,
            route: Route,
        },
		{
            path: '/account/reset-password/:reset_code',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        }
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
	dashboardCitizenRoutes,
    dashboardRoutes,
	dashboardSuperMinistryRoutes,
	//adminDashboardv2Routes,
	profilepageRoutes,
	//submitIssueRoutes,
	pollingServicesRoutes,
	//userIssuesRoutes,	
	mdanodalofficerRoutes,
	mdaNodalOfficerIssuesRoutes,
	reRouteIssuesRoutes,
	//officerIssuesRoutes,
	changepasswordRoutes,
	
	IssuesReRoutesRoutes,
	
	mdaRoutes,
	mdauserRoutes,
	AllUsersRoutes,
	datavariableRoutes,
	issuesRoutes,
	pollingRoutes,
	//infographicsRoutes,
	
	callsHistoryRoutes,
	receiveCallRoutes,
	callHistoryRoutes,
	chatRoutes,
	chatMinistryRoutes,
	chatControlCenterRoutes,
	chatCallCenterRoutes,
	
	issueListRoutes,
	ideasRoutes,
	
    ...appRoutes,
    authRoutes
];

const authProtectedRoutes = [
								mdanodalofficerRoutes,mdaNodalOfficerIssuesRoutes,dashboardRoutes,dashboardSuperMinistryRoutes,/*adminDashboardv2Routes,*/mdaRoutes,mdauserRoutes,AllUsersRoutes,datavariableRoutes,issuesRoutes,pollingRoutes,
								/*infographicsRoutes,*/
								callsHistoryRoutes,receiveCallRoutes,callHistoryRoutes,
								IssuesReRoutesRoutes,
								chatRoutes,chatCallCenterRoutes,
								chatMinistryRoutes,chatControlCenterRoutes,								
								profilepageRoutes,/*submitIssueRoutes,userIssuesRoutes,*/pollingServicesRoutes,
								changepasswordRoutes,/*officerIssuesRoutes,*/
								ideasRoutes,
							...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
