import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import axios from 'axios';
import { apiURL } from '../constants/defaultValues';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes } from './index';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerticalLayout = Loadable({
    loader: () => import('../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

class Routes extends Component {
	
	refreshRerouteCounts(){
		const loggedInUser = getLoggedInUser();
		var  that=this;
		if(loggedInUser!=null && typeof loggedInUser!='undefined'){
			
			if(loggedInUser.role==='Control Center' || loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor' || loggedInUser.role==='Administrator'){
				document.getElementById('ideas_total_counts').classList.remove("d-none");
			}else{
				if(that.interval2!=null && typeof that.interval2!='undefined'){
					clearInterval(that.interval2);
				}
			}
			
			if(loggedInUser.role==='Control Center'){
				that.interval = setInterval(() =>{
					axios.get(apiURL+'/api/issue/re-route-counts').then((res)=>{ 
						if(typeof res.data.issue_count!='undefined'){					
							document.getElementById('control_center-re_routes_counts').innerHTML=res.data.issue_count;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
				}, 10000);
			}
			if(loggedInUser.role==='Control Center' || loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor'){
				let post_data={};
				post_data.user_id = loggedInUser.id;
				post_data.mda_id = loggedInUser.mda_id;
				axios.post(apiURL+'/api/chat/message-count',post_data).then((res)=>{ 
					if(typeof res.data.data!='undefined'){					
						document.getElementById('contact_ministry-chat_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));	
				
				let req_data = {mda_id:loggedInUser.mda_id};
				if(loggedInUser.role==='User'){
					req_data = {user_id:loggedInUser.id};
				}
				axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
					if(typeof res.data.data!='undefined'){					
						document.getElementById('ideas_total_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));	
				
				that.interval2 = setInterval(() =>{
					var post_data={};
					post_data.user_id = loggedInUser.id;
					post_data.mda_id = loggedInUser.mda_id;
					axios.post(apiURL+'/api/chat/message-count',post_data).then((res)=>{ 
						if(typeof res.data.data!='undefined'){					
							document.getElementById('contact_ministry-chat_counts').innerHTML=res.data.data;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
					
					let req_data = {mda_id:loggedInUser.mda_id};
					if(loggedInUser.role==='User'){
						req_data = {user_id:loggedInUser.id};
					}
					axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
						if(typeof res.data.data!='undefined'){					
							document.getElementById('ideas_total_counts').innerHTML=res.data.data;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
				
				}, 10000);
			}
			
			if(loggedInUser.role==='Control Center' || loggedInUser.role==='Call Center'){
				let post_data={};
				post_data.user_id = loggedInUser.id;
				if(loggedInUser.role==='Call Center'){
					post_data.call_center_id = loggedInUser.id;
				}
				axios.post(apiURL+'/api/call-center-chat/message-count',post_data).then((res)=>{ 
					if(typeof res.data.data!='undefined'){					
						document.getElementById('contact_call_center-chat_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));
				
				that.interval3 = setInterval(() =>{
					var post_data={};
					post_data.user_id = loggedInUser.id;
					if(loggedInUser.role==='Call Center'){
						post_data.call_center_id = loggedInUser.id;
					}
					axios.post(apiURL+'/api/call-center-chat/message-count',post_data).then((res)=>{ 
						if(typeof res.data.data!='undefined'){					
							document.getElementById('contact_call_center-chat_counts').innerHTML=res.data.data;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
				}, 10000);
			}
			
			if(loggedInUser.role==='Administrator'){
				let req_data = {mda_id:loggedInUser.mda_id};
				if(loggedInUser.role==='User'){
					req_data = {user_id:loggedInUser.id};
				}
				axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
					if(typeof res.data.data!='undefined'){					
						document.getElementById('ideas_total_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));	
				
				that.interval4 = setInterval(() =>{
					let req_data = {mda_id:loggedInUser.mda_id};
					if(loggedInUser.role==='User'){
						req_data = {user_id:loggedInUser.id};
					}
					axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
						if(typeof res.data.data!='undefined'){					
							document.getElementById('ideas_total_counts').innerHTML=res.data.data;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
				
				}, 10000);
			}

		}else{
			setTimeout(function(){
				that.refreshRerouteCounts();			
			}, 2000);
		}
	}
	
	componentDidMount() {
		var  that=this;
		setTimeout(function(){			
			that.refreshRerouteCounts();			
		}, 5000);
	}
	
	componentWillUnmount() {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser!=null){
			if(loggedInUser.role==='Control Center'){
				clearInterval(this.interval);
				clearInterval(this.interval2);
				clearInterval(this.interval3);
			}else if(loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor'){
				clearInterval(this.interval2);
			}
			if(loggedInUser.role!=='Control Center' && this.interval!=null && typeof this.interval!='undefined'){
				clearInterval(this.interval);
			}
			
			if((loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor') && this.interval2!=null && typeof this.interval2!='undefined'){
				clearInterval(this.interval2);
			}
			
			if((loggedInUser.role==='Call Center') && this.interval3!=null && typeof this.interval3!='undefined'){
				clearInterval(this.interval3);
			}
			
			if((loggedInUser.role==='Administrator') && this.interval4!=null && typeof this.interval4!='undefined'){
				clearInterval(this.interval4);
			}
			
		}
	}

    // returns the layout
    getLayout = () => {
		var pathname = window.location.pathname.split('/');
        if (!isUserAuthenticated()) return AuthLayout;
		
		if (pathname.indexOf('email-confirmed')!==-1) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    render() {
        const Layout = this.getLayout();
        
        // rendering the router with layout
        return <BrowserRouter>
            <Layout {...this.props}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            !route.children ?
                                <route.route
                                    key={index}
                                    path={route.path}
                                    roles={route.roles}
                                    exact={route.exact}
                                    component={route.component}></route.route>
                                : null
                        );
                    })}
                </Switch>
            </Layout>
        </BrowserRouter>
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
